/* CSS file (e.g., App.css) */

.chart-container {
  position: relative;
  /* padding-bottom: 1px; */
}

.chart-fade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px; /* Height of the fading effect */
  background: linear-gradient(to top, #ffffff, transparent);
  z-index: 2;
}

.fade-content {
  padding: 20px;
  background-color: #ffffff; /* Match the fade color to your background */
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
