@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    @apply hidden;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .gradient {
    @apply bg-gradient-to-r from-[#06A1F9] to-[#2246C3];
  }

  .gradient-text {
    @apply bg-gradient-to-r from-[#06A1F9] to-[#2246C3] bg-clip-text text-transparent;
  }

  .h1 {
    @apply text-[32px] font-bold leading-10 text-new-accent;
  }

  .h2 {
    @apply text-lg font-bold leading-5 text-new-accent;
  }

  .label {
    @apply text-2xl font-semibold leading-7 text-new-accent;
  }

  .shadow {
    @apply shadow-[0px_4px_16px_rgba(134,134,134,0.2)];
  }

  .shadow-sm {
    @apply shadow-[0px_4px_10px_rgba(55,102,196,0.15)];
  }

  .ellipsis {
    @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
  }

  .after-star {
    @apply after:ml-1 after:text-red-500 after:content-['*'];
  }

  .editor::-webkit-scrollbar-track {
    @apply bg-new-editor-dark-100;
  }

  .editor::-webkit-scrollbar-thumb {
    @apply bg-[new-editor-dark-400];
  }

  .mentor-schedule::-webkit-scrollbar-track {
    @apply bg-[#FAFAFA];
  }

  .mentor-schedule::-webkit-scrollbar-thumb {
    @apply bg-[#6B6B6B];
  }

  .custom-shadow {
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  }

  .bg-custom-image {
    background-image: url("./assets/images/DoubtSupportBackground.png");
    background-size: cover;
    background-position: center center;
    filter: blur(20px);
  }

  .PhoneInput {
    @apply w-full;
  }

  .PhoneInput input {
    @apply rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-4 text-new-neutral outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #312e81;
    border-radius: 10px;
  }

  .gray-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 10px;
  }

  .gray-scrollbar::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    /* || transparent */
    border-radius: 10px;
  }

  .shortcut-tab {
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    text-align: center;
    color: #001665;
  }

  .custom-gradient {
    background: linear-gradient(160deg, #1c5081 22.41%, #0f2030 81.72%);
  }

  .max-w-full-32 {
    max-width: calc(100% - 32px);
  }

  .card-shadow {
    box-shadow: -1px 1px 3px 0px rgba(132,136,139,0.05);
  }

  /* v2 */
  .heading1 {
    @apply text-[42px] font-semibold leading-normal;
  }

  .heading1-1 {
    @apply text-[42px] font-medium leading-normal;
  }

  .heading1-2 {
    @apply text-[42px] font-normal leading-normal;
  }

  .heading2 {
    @apply text-[32px] font-semibold leading-normal;
  }

  .heading2-1 {
    @apply text-[32px] font-medium leading-normal;
  }

  .heading2-2 {
    @apply text-[32px] font-normal leading-normal;
  }

  .heading3 {
    @apply text-[24px] font-semibold leading-normal;
  }

  .heading3-1 {
    @apply text-[24px] font-medium leading-normal;
  }

  .heading3-2 {
    @apply text-[24px] font-normal leading-normal;
  }

  .heading4 {
    @apply text-[18px] font-semibold leading-normal;
  }

  .heading4-1 {
    @apply text-[18px] font-medium leading-normal;
  }

  .heading4-2 {
    @apply text-[18px] font-normal leading-normal;
  }

  .heading5 {
    @apply text-[16px] font-semibold leading-normal;
  }

  .heading5-1 {
    @apply text-[16px] font-medium leading-normal;
  }

  .heading5-2 {
    @apply text-[16px] font-normal leading-normal;
  }

  .heading6 {
    @apply text-[14px] font-semibold leading-normal;
  }

  .heading6-1 {
    @apply text-[14px] font-medium leading-normal;
  }

  .heading6-2 {
    @apply text-[14px] font-normal leading-normal;
  }

  .text1 {
    @apply text-[16px] font-semibold leading-normal;
  }

  .text1-1 {
    @apply text-[16px] font-medium leading-normal;
  }

  .text1-2 {
    @apply text-[16px] font-normal leading-normal;
  }

  .text2 {
    @apply text-[14px] font-semibold leading-normal;
  }

  .text2-1 {
    @apply text-[14px] font-medium leading-normal;
  }

  .text2-2 {
    @apply text-[14px] font-normal leading-normal;
  }

  .text3 {
    @apply text-[12px] font-semibold leading-normal;
  }

  .text3-1 {
    @apply text-[12px] font-medium leading-normal;
  }

  .text3-2 {
    @apply text-[12px] font-normal leading-normal;
  }

  .text4 {
    @apply text-[10px] font-semibold leading-normal;
  }

  .text4-1 {
    @apply text-[10px] font-medium leading-normal;
  }

  .text4-2 {
    @apply text-[10px] font-normal leading-normal;
  }

  .para1 {
    @apply text-[18px] font-medium leading-[150%];
  }

  .para1-1 {
    @apply text-[18px] font-normal leading-[150%];
  }

  .para1-2 {
    @apply text-[18px] font-light leading-[150%];
  }

  .para2 {
    @apply text-[14px] font-medium leading-[150%];
  }

  .para2-1 {
    @apply text-[14px] font-normal leading-[150%];
  }

  .para2-2 {
    @apply text-[14px] font-light leading-[150%];
  }

  .para3 {
    @apply text-[12px] font-medium leading-[150%];
  }

  .para3-1 {
    @apply text-[12px] font-normal leading-[150%];
  }

  .para3-2 {
    @apply text-[12px] font-light leading-[150%];
  }
}

@layer base {
  h1 {
    @apply text-[32px] font-bold leading-10;
  }

  h2 {
    @apply text-2xl font-bold leading-8;
  }

  h3 {
    @apply text-lg font-semibold leading-5;
  }

  h4 {
    @apply text-sm font-semibold leading-4;
  }

  h5 {
    @apply text-[10px] font-medium leading-normal;
  }

  li,
  p,
  textarea {
    @apply text-sm leading-5 focus:border-transparent focus:outline-none focus:ring-0;
  }

  a {
    @apply leading-5;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #737373 !important;
  }

  ::placeholder {
    @apply select-none;
  }

  ::-webkit-scrollbar {
    @apply max-h-[8px] max-w-[8px];
  }

  ::-webkit-scrollbar-track {
    @apply bg-new-solid-blue-light;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-new-neutral-light;
  }

  /* v2 */
  :root {
    /* colors */
    --white: #ffffff;
    --gray-100: #f9f9f9;
    --gray-200: #ebebeb;
    --gray-300: #e3e3e3;
    --gray-400: #d3d3d3;
    --gray-500: #646464;
    --gray-600: #333333;
    --gray-700: #0c0c0c;
    --accent-100: #4f609b;
    --accent-200: #03249a;
    --accent-300: #001665;
    --accent-400: #000f43;
    --failure: #dc2626;
    --success: #00c22b;
    --gold: #fbbf24;
    --blue: #9bdeff;
    --blue-light: #eef8fd;
    --green: #68ee86;
    --green-light: #edfff2;
    --yellow: #f4f976;
    --yellow-light: #feffe4;
    --red: #fc8888;
    --red-light: #ffeded;
    --violet: #a27cf3;
    --violet-light: #ededff;
    --purple: #d270e5;
    --purple-light: #fcedff;

    /* border radius */
    --radius: 0.25rem;

    /* font */
    --font-sans: "Inter";
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.slide-in {
  animation: slideIn 1s ease-in-out;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  border-radius: 5px;
}

.custom-scrollbar-2::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 5px;
}

/* styles.css */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.no-ring:focus {
  outline: none;
  box-shadow: none;
}

.no-hover:hover {
  /* Reset common hover effects */
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

@media (min-width: 1280px) {
  .main-content {
    min-width: 747px;
  }
}

@media (min-width: 1920px) {
  .main-content {
    max-width: 1045px;
  }
}

.rcs-inner-handle {
  background-color: #d3d3d399 !important;
}

.PhoneInputCountry{
  width:58px;
  display: flex;
  justify-content: center;
  margin-right: 0 !important;
  border-width: 1px;
  border-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: #d1d5db;
}

/* .PhoneInputInput{
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
} */

.PhoneInput input{
  /* border-radius: 4px; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  border-color: #d1d5db;
}

input.PhoneInputInput::placeholder{
  /* color:var(--text-new-neutral-light); */
  color:text-new-neutral;
  opacity: 1;
}



/* Custom Scrollbar Styles */
.scrollbar-hidden::-webkit-scrollbar {
  opacity: 0; /* Hide scrollbar by default */
}

.scrollbar-visible::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: #d8d8d8; /* Scrollbar color */
  border-radius: 4px;
}

.scrollbar-visible::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-container:hover .scrollbar-hidden {
  overflow-x: auto; /* Show scrollbar on hover */
}

.scrollbar-container:hover .scrollbar-hidden::-webkit-scrollbar {
  opacity: 1;
}



/* Custom Scrollbar Styles */
.scrollbar-hidden::-webkit-scrollbar {
  opacity: 0; /* Hide scrollbar by default */
}

.scrollbar-visible::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: #d8d8d8; /* Scrollbar color */
  border-radius: 4px;
}

.scrollbar-visible::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-container:hover .scrollbar-hidden {
  overflow-x: auto; /* Show scrollbar on hover */
}

.scrollbar-container:hover .scrollbar-hidden::-webkit-scrollbar {
  opacity: 1;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.slick-dots li{
  margin: 0 -3px!important;
}